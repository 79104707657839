<template>
  <b-card
    v-if="data"
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="12"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            COD Income
          </h4>
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          height="230"
          :options="chartOptions"
          :series="data.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      revenue_report: {},
      chartOptions: {
        chart: {
          type: 'line',
          toolbar: { show: false },
          zoom: { enabled: true },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [5, 7, 5],
          curve: 'straight',
          dashArray: [0, 8, 5],
        },
        title: {
          text: '',
          align: 'left',
        },
        legend: {
          tooltipHoverFormatter(val, opts) {
            return `${val} - ${opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]}`
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
          categories: this.data.categories.lables,
        },
        grid: {
          borderColor: '#f1f1f1',
        },
        tooltip: {
          y: [
            {
              title: {
                formatter(val) {
                  return `${val} (Rs)`
                },
              },
            },
            {
              title: {
                formatter(val) {
                  return `${val} per session`
                },
              },
            },
            {
              title: {
                formatter(val) {
                  return val
                },
              },
            },
          ],
        },
      },
    }
  },
}
</script>
