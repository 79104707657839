<template>
  <section id="dashboard-ecommerce">

    <b-row class-name="match-height">
      <b-col
        md="4"
        class-name="align-content-center"
      >
        <ecommerce-statistics :data="tempdata.statisticsItems" />
      </b-col>
      <b-col
        md="8"
        class-name="align-content-center"
      >
        <ecommerce-financial-statistics :data="tempdata.financialstatisticsItems" />
      </b-col>
    </b-row>

    <b-row class-name="match-height">
      <b-col
        md="6"
      >
        <div v-if="!isMonthlyEarningsLoading">
          <ecommerce-monthly-earnings-chart :data="tempdata.monthlyEarnings" />
        </div>
      </b-col>
      <b-col
        md="6"
      >
        <div v-if="!isBarChartLoading">
          <ecommerce-revenue-report :data="tempdata.monthlyOrders" />
        </div>
      </b-col>
    </b-row>

    <b-row class-name="match-height">
      <!--      <b-col-->
      <!--        md="3"-->
      <!--      >-->
      <!--        <div v-if="!isCODReceivableLoading">-->
      <!--          <ecommerce-earnings-chart :data="tempdata.codReceivable" />-->
      <!--        </div>-->
      <!--      </b-col>-->
      <b-col
        md="3"
      >
        <div v-if="!isToBeInvoicesLoading">
          <ecommerce-transactions :data="tempdata.toBeInvoices" />
        </div>
      </b-col>

      <!-- Revenue Report Card -->
      <b-col md="9">
        <div v-if="!isMerchantOnBoarding">
          <ecommerce-client-onboarded :data="tempdata.monthlyDeliveryCharge" />
        </div>
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class-name="match-height">
      <!-- Company Table Card -->
      <b-col lg="12">
        <div v-if="!isMStatusStatistics">
          <ecommerce-company-table :table-data="tempdata.companyTable.data" />
        </div>
      </b-col>
      <!--/ Company Table Card -->
    </b-row>

    <b-row class-name="match-height">
      <!-- Company Table Card -->
      <!--      <b-col lg="12">-->
      <!--        <ecommerce-last-few-monthly-earning :data="tempdata.monthlyEarnings" />-->
      <!--      </b-col>-->
      <!--/ Company Table Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import EcommerceStatistics from '@/components/Dashboard/EcommerceStatistics.vue'
import EcommerceTransactions from '@/components/Dashboard/EcommerceTransactions.vue'
import EcommerceFinancialStatistics from '@/components/Dashboard/EcommerceFinancialStatistics.vue'
import EcommerceRevenueReport from '@/components/Dashboard/EcommerceRevenueReport.vue'
// import EcommerceEarningsChart from '@/components/Dashboard/EcommerceEarningsChart.vue'
import EcommerceMonthlyEarningsChart from '@/components/Dashboard/EcommerceMonthlyEarning.vue'
// import EcommerceLastFewMonthlyEarning from '@/components/Dashboard/EcommerceLastFewMonthlyEarning.vue'
import EcommerceCompanyTable from '@/components/Dashboard/EcommerceCompanyTable.vue'
import EcommerceClientOnboarded from '@/components/Dashboard/EcommerceClientOnboarded.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('dashboard')

export default {
  components: {
    EcommerceClientOnboarded,
    BRow,
    BCol,
    EcommerceTransactions,
    // EcommerceLastFewMonthlyEarning,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceFinancialStatistics,
    EcommerceMonthlyEarningsChart,
    // EcommerceEarningsChart,
    EcommerceCompanyTable,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      data: {},
      isBarChartLoading: false,
      isMonthlyEarningsLoading: false,
      isCODReceivableLoading: false,
      isToBeInvoicesLoading: false,
      isMerchantOnBoarding: false,
      isMStatusStatistics: false,
      tempdata: {
        statisticsItems: [
          {
            icon: 'BoxIcon',
            customClass: '',
            color: 'light-warning',
            title: 0,
            subtitle: 'Orders Count',
          },
          {
            icon: 'DollarSignIcon',
            customClass: 'ml-3',
            color: 'light-success',
            title: 0,
            subtitle: 'Total Earnings',
          },
        ],
        financialstatisticsItems: [
          {
            icon: 'ShoppingBagIcon',
            customClass: '',
            color: 'light-info',
            title: 0,
            subtitle: 'Invoice Value',
          },
          {
            icon: 'LoaderIcon',
            customClass: '',
            color: 'light-warning',
            title: 0,
            subtitle: 'Pending Invoice',
          },
          {
            icon: 'CheckCircleIcon',
            customClass: '',
            color: 'light-success',
            title: 0,
            subtitle: 'Paid Invoice Value',
          },
          {
            icon: 'UserCheckIcon',
            customClass: '',
            color: 'light-primary',
            title: 0,
            subtitle: 'Approved Invoice',
          },
        ],
        monthlyEarnings: {
          categories:
              { lables: [] },
          series: [
            {
              name: 'Delivery Charge',
              data: [],
            },
          ],
        },
        codReceivable: {
          series: [],
        },
        toBeInvoices: [
          {
            avatarVariant: 'light-warning',
            avatar: 'CodesandboxIcon',
            mode: 'COD',
            types: 'LKR',
            deduction: false,
            payment: 0,
          },
          {
            avatarVariant: 'light-success',
            avatar: 'TruckIcon',
            mode: 'Delivery Charge',
            types: 'LKR',
            deduction: false,
            payment: 0,
          },
          {
            avatarVariant: 'light-info',
            avatar: 'DollarSignIcon',
            mode: 'Invoice Value',
            types: 'LKR',
            deduction: false,
            payment: 0,
          },
        ],
        monthlyOrders: {
          categories:
              { lables: [] },
          series: [
            { data: [] },
          ],
        },
        monthlyDeliveryCharge: {
          categories:
              { lables: [] },
          series: [
            {
              name: 'Delivery Expenses',
              data: [],
            },
            {
              name: 'Partially Delivered',
              data: [],
            },
            {
              name: 'Return Income',
              data: [],
            },
          ],
        },
        companyTable: {
          data: [],
        },
      },
    }
  },
  created() {
    // data
  },
  mounted() {
    // Set the initial number of items
    // this.readDataforOrderStats()
    // this.readDataforFinancialStats()
    // this.readDataforMonthlyOrders()
    // this.readDataforMonthlyEarnings()
    // this.readDataforCODReceivable()
    // this.readDataforToBeInvoices()
    // this.readDataforMerchantOnBoarding()
    // this.readDataforStatusStatistics()
  },
  methods: {
    currentDate() {
      const current = new Date()
      return `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
    },
    async readDataforOrderStats() {
      try {
        const { data } = (await ResourceRepository.getOrderStatsFinance()).data
        this.tempdata.statisticsItems[0].title = data.order_count
        this.tempdata.statisticsItems[1].title = data.total_earning
      } catch (e) {
        this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
      }
    },
    async readDataforFinancialStats() {
      try {
        const { data } = (await ResourceRepository.getFinancialStatFinance(this.id)).data
        this.tempdata.financialstatisticsItems[0].title = data.total_invoice_amount
        this.tempdata.financialstatisticsItems[1].title = data.status_amount[0].amount
        this.tempdata.financialstatisticsItems[2].title = data.total_earning[1].amount
        this.tempdata.financialstatisticsItems[3].title = data.total_earning[2].amount
      } catch (e) {
        this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
      }
    },
    async readDataforMonthlyOrders() {
      this.isBarChartLoading = true
      try {
        const { data } = (await ResourceRepository.getMonthlyOrdersFinance()).data
        this.tempdata.monthlyOrders.categories.lables = data.map(n => (`${n.month}${n.year}`))
        this.tempdata.monthlyOrders.series[0].data = data.map(n => n.order_count)
      } catch (e) {
        this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
      }
      this.isBarChartLoading = false
    },
    async readDataforMonthlyEarnings() {
      this.isMonthlyEarningsLoading = true
      try {
        const { data } = (await ResourceRepository.getMonthlyEarningsFinance()).data
        this.tempdata.monthlyEarnings.categories.lables = data.map(n => (`${n.month}${n.year}`))
        this.tempdata.monthlyEarnings.series[0].data = data.map(n => n.delivery_charge)
      } catch (e) {
        this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
      }
      this.isMonthlyEarningsLoading = false
    },
    async readDataforCODReceivable() {
      this.isCODReceivableLoading = true
      try {
        const { data } = (await ResourceRepository.getCODRecivableFinance()).data
        this.tempdata.codReceivable.series[0] = data.total_receivable_cod
        this.tempdata.codReceivable.series[1] = data.agency_receivable_commission
        this.tempdata.codReceivable.series[2] = data.branch_receivable_cod
      } catch (e) {
        this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
      }
      this.isCODReceivableLoading = false
    },
    async readDataforToBeInvoices() {
      this.isToBeInvoicesLoading = true
      try {
        const { data } = (await ResourceRepository.getToBEInvoicesFinance()).data
        if (data[0].cod === null) {
          this.tempdata.toBeInvoices[0].payment = 0
        } else {
          this.tempdata.toBeInvoices[0].payment = data[0].cod
        }
        if (data[0].delivery_charge === null) {
          this.tempdata.toBeInvoices[1].payment = 0
        } else {
          this.tempdata.toBeInvoices[1].payment = data[0].delivery_charge
        }
        if (data[0].delivery_charge === null) {
          this.tempdata.toBeInvoices[2].payment = 0
        } else {
          this.tempdata.toBeInvoices[2].payment = data[0].collected_cod
        }
      } catch (e) {
        this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
      }
      this.isToBeInvoicesLoading = false
    },
    async readDataforMerchantOnBoarding() {
      this.isMerchantOnBoarding = true
      try {
        const { data } = (await ResourceRepository.getmonthlyDeliveryChargeFinance()).data
        this.tempdata.monthlyDeliveryCharge.categories.lables = this.currentDate
        if (data.delivery.charges === null || data.delivery.charges === undefined || data.delivery.charges === '' || data.delivery.charges === 0) {
          this.tempdata.monthlyDeliveryCharge.series[0].data = [0]
        } else {
          this.tempdata.monthlyDeliveryCharge.series[0].data = [data.delivery.charges]
        }
        if (data.partially.charges === null || data.partially.charges === undefined || data.partially.charges === '' || data.partially.charges === 0) {
          this.tempdata.monthlyDeliveryCharge.series[1].data = [0]
        } else {
          this.tempdata.monthlyDeliveryCharge.series[1].data = [data.partially.charges]
        }
        if (data.return.charges === null || data.return.charges === undefined || data.return.charges === '' || data.return.charges === 0) {
          this.tempdata.monthlyDeliveryCharge.series[2].data = [0]
        } else {
          this.tempdata.monthlyDeliveryCharge.series[2].data = [data.return.charges]
        }
      } catch (e) {
        this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
      }
      this.isMerchantOnBoarding = false
    },
    async readDataforStatusStatistics() {
      this.isMStatusStatistics = true
      try {
        const { data } = (await ResourceRepository.getStatusStatisticsFinance()).data
        this.tempdata.companyTable.data = data
      } catch (e) {
        this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
      }
      this.isMStatusStatistics = false
    },
  },
}
</script>

<style lang="scss">
@import '../@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '../@core/scss/vue/libs/chart-apex.scss';
</style>
